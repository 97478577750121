.title-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-line > h1 {
    text-decoration: underline;
}

.title-line > button {
    height: 1.5rem;
}

.header > p {
    margin-top: 0;
}
