
.auth > input {
    margin-bottom: 1rem;
}

.btns {
    display: flex;
    align-items: center;

    gap: 1rem;
}

.btns > button {
}